.module {
  padding-top: 24px;
  padding-bottom: 24px;

  :where(& > *:not(:last-child)) {
    margin-bottom: 16px;

    @media only screen and #{$sm-min-width} {
      margin-bottom: 32px;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .module {
  }
}
