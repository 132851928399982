$subPageNavigationSpacing: 8px;

.sub-page-navigation {
  &-label-wrapper {
    padding: 42px 0;
    color: $primaryColor50;
    background-color: $primaryColor950;
  }

  &-label {
    @include fontH2();
  }

  .breadcrumbs {
    &-list {
      display: flex;
      gap: $subPageNavigationSpacing;
      flex-wrap: wrap;
      width: 100%;
      padding: 12px 0;
    }

    &-item {
      display: inline-flex;
      align-items: center;

      &:not(:last-child) {
        &::after {
          content: "-";
          display: inline-flex;
          align-items: center;
          margin-left: $subPageNavigationSpacing;
        }
      }
    }

    &-element {
      color: $greyColor900;
      @include transition(box-shadow, background-color, color, border-color);

      &:is(a, button) {
        color: $greyColor900;

        &:hover,
        &:focus {
          text-decoration: underline;
          color: $greyColor900;
        }
      }
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .sub-page-navigation {
    border-top: 2px solid $contrastYellowColor;
    border-bottom: 2px solid $contrastYellowColor;

    &-label-wrapper {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border-bottom: 2px solid $contrastYellowColor;
    }

    .breadcrumbs {
      &-element {
        color: $contrastYellowColor;

        &:is(a, button) {
          color: $contrastWhiteColor;

          &:hover,
          &:focus {
            text-decoration: underline;
            color: $contrastWhiteColor;
          }
        }
      }
    }
  }
}
