$minHeight: calculateRem(300px);
$lgMinHeight: calculateRem(400px);

.jumbotrons-slider-v1 {
  position: relative;
  overflow: hidden;

  .slick {
    &-controls,
    &-navigation {
      position: absolute;
      right: 0;
      left: 0;

      & .container {
        display: flex;
        gap: 12px;

        & > * {
          flex-shrink: 0;
        }
      }
    }

    &-controls {
      top: 8px;

      @media only screen and #{$lg-min-width} {
        top: 32px;
      }

      & .container {
        align-items: center;
        justify-content: end;
      }
    }

    &-navigation {
      bottom: 8px;

      @media only screen and #{$lg-min-width} {
        bottom: 32px;
      }

      & .container {
        align-items: center;
        justify-content: space-between;
      }
    }

    &-arrow {
      position: relative;
      padding: 0;
      color: $primaryColor50;
      background-color: $primaryColor950;
      border-radius: $borderRadiusSm;

      &:hover,
      &:focus {
        color: $primaryColor50;
        background-color: $primaryColor990;
      }
    }

    &-pause-play {
      margin-left: 12px;
    }
  }

  &-list {
    position: relative;
    min-height: $minHeight;
    margin-bottom: 0;
    padding-left: 0;

    @media only screen and #{$lg-min-width} {
      min-height: $lgMinHeight;
    }
  }

  &-item {
    position: relative;
    display: none;
    flex-direction: column;
    height: 100%;
    min-height: $minHeight;
    -webkit-backface-visibility: hidden;

    &:first-child {
      display: grid !important;
      grid-template-columns: repeat(4, 1fr);
      -webkit-backface-visibility: visible;
    }

    @media only screen and #{$lg-min-width} {
      min-height: $lgMinHeight;
      -webkit-backface-visibility: visible;
    }

    @media only screen and #{$lg-max-width} {
      max-width: 100%;
    }

    &.fullWidth {
      .jumbotrons-slider-v1-content-wrapper {
        @media only screen and #{$lg-min-width} {

        }
      }
    }

    &:is(.halfWidthLeft) {
      .jumbotrons-slider-v1-content-wrapper {
        @media only screen and #{$lg-min-width} {
          grid-column: 1 / 3;
        }
      }
    }

    &:is(.halfWidthRight) {
      .jumbotrons-slider-v1-content-wrapper {
        @media only screen and #{$lg-min-width} {
          grid-column: 3 / 5;
        }
      }
    }
  }

  &-image-wrapper {
    position: relative;
    height: 100%;
    min-height: $minHeight;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    aspect-ratio: 1920 / 300;

    @media only screen and #{$lg-min-width} {
      min-height: $lgMinHeight;
      aspect-ratio: 1920 / 400;
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &-content-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 56px 0;
    grid-column: 1 / -1;
    grid-row: 1 / -1;

    @media only screen and #{$lg-min-width} {
      padding: 80px 0;
    }
  }

  &-content {
    padding-top: 24px;
    padding-bottom: 24px;
    color: $lightTextColor;
    background-color: $primaryColor950;
    border-radius: $borderRadiusSm;

    & .container {
      display: grid;
      gap: 12px;
    }

    &.textAlignLeft {
      text-align: left;

      .jumbotrons-slider-v1-buttons {
        text-align: left;
      }
    }

    &.textAlignCenter {
      text-align: center;

      .jumbotrons-slider-v1-buttons {
        text-align: center;
      }
    }

    &.textAlignRight {
      text-align: right;

      .jumbotrons-slider-v1-buttons {
        text-align: right;
      }
    }
  }

  &-heading {
    @include fontH4();

    @media only screen and #{$md-min-width} {
      @include fontH3();
    }

    @media only screen and #{$lg-min-width} {
      @include fontH2();
    }
  }

  &-subheading {
    @include fontH5();

    @media only screen and #{$md-min-width} {
      @include fontH4();
    }

    @media only screen and #{$lg-min-width} {
      @include fontH3();
    }
  }

  &-text {
    @include fontH6();

    @media only screen and #{$md-min-width} {
      @include fontH5();
    }

    @media only screen and #{$lg-min-width} {
      @include fontH4();
    }
  }

  &-buttons {
    margin-top: 12px;
  }
}

// ------------------CONTRAST------------------

.contrast {
  .jumbotrons-slider-v1 {
    color: $contrastYellowColor;
    background-color: $contrastBlackColor;
    border-top: 2px solid $contrastYellowColor;

    .slick {
      &-arrow {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;

        &:hover,
        &:focus {
          color: $contrastBlackColor;
          background-color: $contrastYellowColor;
        }
      }
    }

    &-content {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border-top: 2px solid $contrastYellowColor;
    }
  }
}
