.layout-news {
  .breadcrumbs {
    background-color: $primaryColor50;
  }

  .module-news-background {
    background-color: $primaryColor50;
  }
}

.module-news {
  &-search {
    margin-bottom: 8px;
    
    &-grid {
      display: flex;
      gap: 16px;
      flex-direction: column;

      @media only screen and #{$lg-min-width} {
        flex-direction: row;
      }
    }

    &-item {
      position: relative;

      &:first-child {
        flex-grow: 1;
      }
    }

    .btn {
      width: 100%;
      height: 100%;
    }
  }

  &-list {
    display: grid;
    justify-content: center;
    gap: 24px;
    margin-bottom: 24px;

    @media only screen and #{$md-min-width} {
      grid-template-columns: 1fr 1fr;
    }

    &.more {
      @media only screen and #{$xl-min-width} {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// ------------------CONTRAST------------------
.contrast {
  .layout-news {
    .breadcrumbs {
      background-color: $contrastBlackColor;
    }

    .module-news-background {
      background-color: $contrastBlackColor;
    }
  }
}
