// Paths
$assetsPath: "/layouts/szpital_strusia/assets";

// Fonts
$fontFamily: "Poppins", system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
$fontIcon: "tabler-icons" !default;

@mixin fontHeading($fontWeight: 500) {
  @include font(32px, 1.5, $fontWeight);
}

@mixin fontH1($fontWeight: 700) {
  @include font(28px, 1.5, $fontWeight);
}

@mixin fontH2($fontWeight: 700) {
  @include font(26px, 1.5, $fontWeight);
}

@mixin fontH3($fontWeight: 700) {
  @include font(24px, 1.5, $fontWeight);
}

@mixin fontH4($fontWeight: 700) {
  @include font(20px, 1.5, $fontWeight);
}

@mixin fontH5($fontWeight: 700) {
  @include font(18px, 1.5, $fontWeight);
}

@mixin fontH6($fontWeight: 700) {
  @include font(16px, 1.5, $fontWeight);
}

@mixin fontXl($fontWeight: 500) {
  @include font(20px, 1.5, $fontWeight);
}

@mixin fontLg($fontWeight: 500) {
  @include font(18px, 1.5, $fontWeight);
}

@mixin fontMd($fontWeight: 400) {
  @include font(16px, 1.5, $fontWeight);
}

@mixin fontSm($fontWeight: 400) {
  @include font(14px, 1.5, $fontWeight);
}

@mixin fontXs($fontWeight: 400) {
  @include font(12px, 1.5, $fontWeight);
}

// Border Radius
$borderRadiusSm: 6px;
$borderRadiusLg: 8px;
$roundedBorderRadius: 400px;

// Shadows
$shadowSm: 0px 2px 4px 0px rgba(58, 57, 76, 0.03), 0px 2px 8px 0px rgba(58, 57, 76, 0.08);
$shadowMd: 0px 4px 6px 0px rgba(58, 57, 76, 0.06), 0px 6px 20px -2px rgba(58, 57, 76, 0.14);
$shadowLg: 0px 6px 8px 0px rgba(58, 57, 76, 0.06), 0px 9px 34px -4px rgba(58, 57, 76, 0.14);

// Outline
$outlineStyle: solid;
$outlineWidth: 2px;
$outlineOffset: 3px;

// Transition
$transitionDuration: 0.2s;
$transitionTimingFunction: ease;

// Spacings
$spacingXxs: 4px;
$spacingXs: 8px;
$spacingSm: 12px;
$spacingMd: 16px;
$spacingLg: 24px;
$spacingXl: 32px;
$spacingXxl: 42px;

// Gutters
$gutterTile: 4px;
$gutterArticle: 10px;

// Breakpoints
$smBreakpoint: 640px;
$mdBreakpoint: 768px;
$lgBreakpoint: 1024px;
$xlBreakpoint: 1280px;
$xxlBreakpoint: 1536px;

$sm-min-width: "(min-width: #{$smBreakpoint})";
$sm-max-width: "(max-width: #{$smBreakpoint - 1})";

$md-min-width: "(min-width: #{$mdBreakpoint})";
$md-max-width: "(max-width: #{$mdBreakpoint - 1})";

$lg-min-width: "(min-width: #{$lgBreakpoint})";
$lg-max-width: "(max-width: #{$lgBreakpoint - 1})";

$xl-min-width: "(min-width: #{$xlBreakpoint})";
$xl-max-width: "(max-width: #{$xlBreakpoint - 1})";

$xxl-min-width: "(min-width: #{$xxlBreakpoint})";
$xxl-max-width: "(max-width: #{$xxlBreakpoint - 1})";

$breakpoints: (
  'sm': $smBreakpoint,
  'md': $mdBreakpoint,
  'lg': $lgBreakpoint,
  'xl': $xlBreakpoint,
  'xxl': $xxlBreakpoint
);

@mixin responsive($class, $property, $values) {
  @each $modifier, $value in $values {
    .#{$class}-#{$modifier} {
      #{$property}: $value !important;
    }

    @each $breakpoint, $min-width in $breakpoints {
      @media only screen and (min-width: $min-width) {
        .#{$class}-#{$breakpoint}-#{$modifier} {
          #{$property}: $value !important;
        }
      }
    }
  }
}
