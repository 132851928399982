.news-list-v2 {
  position: relative;
  background-color: $primaryColor950;

  .subheading {
    margin-bottom: 4px;
  }

  .heading {
    margin-bottom: 24px;
  }

  .heading,
  .subheading {
    color: $primaryColor50;
  }

  &-list {
    display: grid;
    justify-content: center;
    gap: 48px;
    margin-bottom: 48px;

    @media only screen and #{$md-min-width} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and #{$xl-min-width} {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &-link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: 100%;
    padding: 32px 12px;
    text-align: center;
    word-break: break-word;
    color: $primaryColor990;
    background-color: $primaryColor50;
    border-radius: $borderRadiusLg;
    @include fontMd();
    @include transition(background-color);

    &:hover,
    &:focus {
      text-decoration: none;
      background-color: $primaryColor100;
    }
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
    flex-wrap: wrap;
  }
}

// ------------------CONTRAST------------------
.contrast {
  .news-list-v2 {
    color: $contrastYellowColor;
    background-color: $contrastBlackColor;
    border-top: 2px solid $contrastYellowColor;

    .heading,
    .subheading {
      color: $contrastYellowColor;
    }

    &-link {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: 2px solid $contrastYellowColor;

      &:hover,
      &:focus {
        text-decoration: none;
        color: $contrastBlackColor;
        background-color: $contrastYellowColor;
        border-color: $contrastYellowColor;
      }
    }
  }
}
