.numbers-list-v1 {
  position: relative;
  background-color: $primaryColor50;

  &.section {
    @media only screen and #{$xl-min-width} {
      padding: 0;
    }
  }

  .container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;

    @media only screen and #{$xl-min-width} {
      position: absolute;
      inset: 0;
      z-index: 1;
    }
  }

  &-headings-wrapper {
    @media only screen and #{$xl-min-width} {
      padding: 12px 0;
    }

    .subheading,
    .heading {
      color: $primaryColor900;

      @media only screen and #{$xl-min-width} {
        margin-bottom: 0;
        color: $primaryColor950;
      }
    }

    .subheading {
      margin-bottom: 4px;
    }

    .heading {
      margin-bottom: 24px;
    }
  }

  &-content {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 12px;
    flex-direction: column;

    @media only screen and #{$xl-min-width} {
      gap: 0;
    }
  }

  &-list {
    display: grid;
    gap: 12px;

    @media only screen and #{$sm-min-width} {
      place-items: center;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    @media only screen and #{$xl-min-width} {
      gap: 24px;
      padding: 30px 0;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 12px 24px;
    color: $primaryColor50;
    background-color: rgba($primaryColor950, 0.9);
    border-radius: $borderRadiusLg;

    @media only screen and #{$xl-min-width} {
      gap: 24px;
      max-width: var(--max-size);
      max-height: var(--max-size);
      padding: 16px;
      border-radius: 100%;
      --max-size: 260px;
      aspect-ratio: 1 / 1;
    }
  }

  &-number {
    @include fontH5();

    @media only screen and #{$xl-min-width} {
      @include fontH3();
    }
  }

  &-text {
    text-align: center;
    @include fontH6();

    @media only screen and #{$xl-min-width} {
      @include fontH4();
    }
  }

  &-footer {
    text-align: right;
    color: $primaryColor950;
    @include fontLg();

    @media only screen and #{$xl-min-width} {
      padding: 12px 0;
    }
  }

  &-background-wrapper {
    position: relative;
    display: none;

    @media only screen and #{$xl-min-width} {
      display: block;
    }
  }

  &-background {
    width: 100%;
    max-height: 800px;
    object-fit: cover;
  }
}

// ------------------CONTRAST------------------
.contrast {
  .numbers-list-v1 {
    background-color: $contrastBlackColor;
    border-top: 2px solid $contrastYellowColor;

    &-headings-wrapper {
      .subheading,
      .heading {
        color: $contrastYellowColor;

        @media only screen and #{$xl-min-width} {
          color: $contrastYellowColor;
        }
      }
    }

    &-item {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: 2px solid $contrastYellowColor;
    }

    &-footer {
      color: $contrastYellowColor;
    }

    &-background-wrapper {
      &::before {
        content: '';
        position: absolute;
        inset: 0;
        z-index: 0;
        background-color: $contrastBlackColor;
      }
    }
  }
}
