.footer {
  color: $lightTextColor;

  :where(a) {
    word-break: break-word;
    color: $lightTextColor;

    &:focus,
    &:hover {
      text-decoration: underline;
      color: $lightTextColor;
    }
  }

  &-wrapper {
    padding: 64px 0;
    background-color: $primaryColor950;

    & > .container {
      display: flex;
      flex-direction: column;
      gap: 64px;
    }
  }

  &-grid {
    display: grid;
    gap: 40px;
    padding-top: 24px;
    border-top: 1px solid $primaryColor50;

    @media only screen and #{$md-min-width} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and #{$lg-min-width} {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &-column {
    display: grid;
    gap: 24px;

    @media only screen and #{$md-min-width} {
      grid-template-rows: minmax(36px, max-content) 1fr;
    }

    & div {
      & > * + *:not(div) {
        margin-top: 2px;
      }

      & > * + *:is(div) {
        margin-top: 12px;
      }
    }
  }

  &-heading {
    text-wrap: balance;
    @include fontMd(700);

    @media only screen and #{$lg-min-width} {
      @include fontXl(700);
    }

    @media only screen and #{$xl-min-width} {
      @include fontH3(700);
    }
  }

  &-subheading {
    @include fontMd();
  }

  &-links {
    a {
      display: block;
    }
  }

  &-copyrights {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 0;
    border-top: 1px solid $primaryColor50;

    @media only screen and #{$sm-min-width} {
      flex-direction: row;
    }

    & > * {
      flex-basis: 0;
      flex-grow: 1;
    }
  }

  &-copy {
    text-align: center;
    word-break: break-word;
    color: $lightTextColor;
    @include fontXs();

    @media only screen and #{$sm-min-width} {
      text-align: left;
    }
  }

  &-sitemap {
    text-align: center;
    word-break: break-word;
    color: $lightTextColor;
    @include fontXs();
  }

  &-realization {
    text-align: center;
    white-space: nowrap;
    word-break: break-word;
    color: $lightTextColor;
    @include fontXs();

    @media only screen and #{$sm-min-width} {
      text-align: right;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .footer {
    border-top: 2px solid $contrastYellowColor;
    background-color: $contrastBlackColor;
    color: $contrastYellowColor;

    :where(a) {
      color: $contrastWhiteColor;

      &:focus,
      &:hover {
        text-decoration: underline;
        color: $contrastYellowColor;
      }
    }

    &-wrapper {
      background-color: $contrastBlackColor;
    }

    &-grid {
      border-top-color: $contrastYellowColor;
    }

    &-copyrights {
      border-top-color: $contrastYellowColor;
    }

    &-copy {
      color: $contrastYellowColor;
    }

    &-sitemap {
      color: $contrastWhiteColor;

      &:focus,
      &:hover {
        text-decoration: underline;
        color: $contrastYellowColor;
      }
    }

    &-realization {
      color: $contrastYellowColor;
    }
  }
}
