.js-point-click {
  cursor: pointer;
}

.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  white-space: nowrap;
  border-width: 0;
}

.uppercase {
  text-transform: uppercase;
}

@include responsive('w', 'width', (
  '0': 0,
  '25': 25%,
  '50': 50%,
  '75': 75%,
  '100': 100%
));

@include responsive('mt', 'margin-top', (
  '0': 0,
  '1': 0.25rem
));

@include responsive('mb', 'margin-bottom', (
  '0': 0,
  '1': 0.25rem
));

@include responsive('d', 'display', (
  'none': none,
  'contents': contents,
  'inline': inline,
  'inline-block': inline-block,
  'block': block,
  'grid': grid,
  'table': table,
  'table-row': table-row,
  'table-cell': table-cell,
  'flex': flex,
  'inline-flex': inline-flex,
));

@include responsive('aspect', 'aspect-ratio', (
  'auto': auto,
  'square': 1 / 1,
  'standard': 4 / 3,
  'standard-vertical': 3 / 4,
  'video': 16 / 9,
  'video-vertical': 9 / 16,
  'news': 630 / 249,
));

@include responsive('whitespace', 'white-space', (
  'normal': normal,
  'nowrap': nowrap,
  'pre': pre,
  'pre-line': pre-line,
  'pre-wrap': pre-wrap
));

@include responsive('text', 'text-align', (
  'left': left,
  'center': center,
  'right': right
));

@keyframes keyframes-animation-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animation-spin {
  animation: keyframes-animation-spin 1s linear infinite;
}

// ------------------CONTRAST------------------
.contrast {

}
