.attachments {
  &-element {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 16px;
    color: $darkTextColor;
    border: 1px solid transparent;
    border-radius: $borderRadiusSm;
    @include fontMd();
    @include transition(box-shadow, background-color, color, border-color);

    &:hover,
    &:focus {
      text-decoration: none;
      border-color: $primaryColor950;
    }

    span {
      white-space: nowrap;
      @include transition(box-shadow, background-color, color, border-color);
    }

    &::before {
      content: $ti-icon-file;
      font-family: $fontIcon;
      font-size: 1.5em;
    }

    &::after {
      content: $ti-icon-download;
      font-family: $fontIcon;
      font-size: 1.5em;
    }

    &-jpg {
      &::before {
        content: $ti-icon-file-type-jpg;
      }
    }

    &-doc {
      &::before {
        content: $ti-icon-file-type-doc;
      }
    }

    &-pdf {
      &::before {
        content: $ti-icon-file-type-pdf;
      }
    }

    &-txt {
      &::before {
        content: $ti-icon-file-type-txt;
      }
    }

    &-rar {
      &::before {
        content: $ti-icon-file-zip;
      }
    }

    &-ppt {
      &::before {
        content: $ti-icon-file-type-ppt;
      }
    }

    &-zip {
      &::before {
        content: $ti-icon-file-type-zip;
      }
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .attachments {
    &-element {
      color: $contrastWhiteColor;
      background-color: $contrastBlackColor;
      border: 2px solid transparent;

      &:hover,
      &:focus {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border: 2px solid $contrastYellowColor;
      }
    }
  }
}
