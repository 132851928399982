$tileBorderRadius: $borderRadiusLg;

.tile {
  display: grid;
  height: 100%;
  border: 2px solid transparent;
  border-radius: $tileBorderRadius;
  @include transition(box-shadow, background-color, color, border-color);

  @media only screen and #{$md-min-width} {
    gap: 24px;
    grid-template-columns: 180px 1fr;
  }

  &.hover {
    text-decoration: none;
    border-color: $blackColor;
  }

  &:not(:has(.tile-image)) {
    .tile-content {
      border-radius: $tileBorderRadius;
    }
  }

  &.bg-primary-50 {
    .tile-content {
      background-color: $primaryColor50;
    }
  }

  &.bg-primary-100 {
    .tile-content {
      background-color: $primaryColor100;
    }
  }

  &:has(.tile-content-wrapper:hover, .tile-content-wrapper:focus) {
    text-decoration: none;
    border-color: $blackColor;
  }

  &:is(a, button) {
    &:hover,
    &:focus {
      text-decoration: none;
      border-color: $blackColor;
    }
  }

  &.vertical {
    display: flex;
    gap: 0;
    flex-direction: column;

    .tile-image {
      height: unset;
      border-radius: $tileBorderRadius $tileBorderRadius 0 0;
    }

    .tile-content {
      padding: 24px 16px;
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &-wrapper {
      position: relative;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        background-repeat: no-repeat;
        background-position: center;
        translate: -50% -50%;
      }

      &.video::before {
        content: '';
        width: var(--size);
        height: var(--size);
        background-image: url("#{$assetsPath}/images/play_circle.svg");
        --size: 52px;
      }
    }
  }

  &-content {
    display: flex;
    gap: 4px;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    background-color: $whiteColor;
    border-radius: 0 0 $tileBorderRadius $tileBorderRadius;
    @include transition(box-shadow, background-color, color, border-color);

    @media only screen and #{$sm-min-width} {
      padding: 24px;
    }

    &-wrapper {
      display: flex;
      gap: 16px;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  &-date {
    color: $darkTextColor;
    @include fontSm();
    @include transition(color);
  }

  &-metadata {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 12px;

    &-item {
    }
  }

  &-title {
    color: $darkTextColor;
    @include fontXl(600);
    @include transition(color);
  }

  &-text {
    color: $darkTextColor;
    @include fontMd();
    @include transition(color);
  }

  &-more {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 18px;
    margin-top: auto;
    padding: 12px;
    color: $primaryColor900;
    @include fontSm(700);
    @include transition(color);
  }

  .js-point-url {
    &:hover,
    &:focus {
      outline: none;
      text-decoration: none;
      border-color: $blackColor;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .tile {
    border-color: $contrastYellowColor;

    &.hover {
      text-decoration: none;
      border-color: $contrastYellowColor;

      .tile-content {
        color: $contrastBlackColor;
        background-color: $contrastYellowColor;
      }
    }

    &:has(.tile-content-wrapper:hover, .tile-content-wrapper:focus) {
      text-decoration: none;
      border-color: $contrastYellowColor;

      .tile-content {
        color: $contrastBlackColor;
        background-color: $contrastYellowColor;
      }
    }

    &:is(a, button) {
      &:hover,
      &:focus {
        text-decoration: none;
        border-color: $contrastYellowColor;

        .tile-content {
          color: $contrastBlackColor;
          background-color: $contrastYellowColor;
        }
      }
    }

    &-content {
      background-color: $contrastBlackColor;
    }

    &-content-wrapper,
    &-date,
    &-title,
    &-text,
    &-more {
      color: inherit;
    }

    .js-point-url {
      &:hover,
      &:focus {
        text-decoration: none;
        border-color: $contrastYellowColor;

        .tile-content {
          color: $contrastBlackColor;
          background-color: $contrastYellowColor;
        }
      }
    }
  }
}
