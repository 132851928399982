.forms-show-v1 {
  position: relative;
  margin-bottom: 16px;

  .fieldset {
    border-radius: $borderRadiusSm;
  }

  &-list {
    display: grid;
    gap: 16px;
    margin-bottom: 32px;
  }

  .label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .form-choice-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
  }

  .form-select {
    max-width: 100%;
  }

  .g-recaptcha {
    margin-bottom: 32px;

    & > div {
      width: 100% !important;
      max-width: 304px;
      height: auto !important;
      max-height: 78px;
    }
  }

  .form-rodo {
    margin-bottom: 32px;
  }

  .alert {
    margin-top: 0;
    margin-bottom: 32px;
  }

  .small {
    display: block;
    text-align: right;
    @include fontSm();
  }
}

// ------------------CONTRAST------------------

.contrast {
  .forms-show-v1 {
  }
}
