.module-contact {
  &-grid {
    display: grid;
    gap: 16px;
    margin-bottom: 32px;

    @media #{$md-min-width} {
      gap: 32px;
      margin-bottom: 64px;
    }

    @media #{$xl-min-width} {
      gap: 64px;
      margin-bottom: 128px;
    }

    &.two-cols {
      @media #{$lg-min-width} {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &.four-cols {
      @media #{$md-min-width} {
        grid-template-columns: repeat(2, 1fr);
      }

      @media #{$xl-min-width} {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  &-subheading {
    @include fontH3();
  }

  p {
    @include fontMd();
    text-align: center;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &-card {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-direction: column;
    border-radius: $borderRadiusLg;
  }

  &-info {
    padding: 36px;
    color: $primaryColor990;
    border: 2px solid $primaryColor950;

    .module-contact-subheading {
      color: $darkTextColor;
    }
  }

  &-additional {
    padding: 36px;
    color: $primaryColor50;
    background-color: $primaryColor950;
  }

  &-employees {
    &-item {
      padding: 36px;
      border-top: 6px solid $primaryColor950;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .module-contact {
    a {
      &:hover,
      &:focus {
        color: $contrastWhiteColor;
      }
    }

    &-info {
      color: $contrastYellowColor;
      border-color: $contrastYellowColor;

      .module-contact-subheading {
        color: $contrastYellowColor;
      }
    }

    &-additional {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: 2px solid $contrastYellowColor;
    }

    &-employees {
      &-item {
        border: 2px solid $contrastYellowColor;
      }
    }
  }
}
