.banners-list-v3 {
  &-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;

    .slick {
      &-slide {
        margin: 0 8px;

        @media only screen and #{$md-min-width} {
          margin: 0 16px;
        }
      }
    }
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: $primaryColor900;
    background-color: $whiteColor;
    border: 2px solid transparent;
    border-radius: $borderRadiusSm;
    -webkit-backface-visibility: hidden;
    @include fontH6();
    @include transition(box-shadow, background-color, color, border-color);

    &:is(a, button) {
      &:hover,
      &:focus {
        text-decoration: none;
        color: $primaryColor50;
        border-color: $primaryColor950;
      }
    }

    &:first-child {
      display: flex;
      -webkit-backface-visibility: visible;
    }
  }

  &-image {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: $borderRadiusSm;
    aspect-ratio: 254 / 88;
    object-fit: contain;
  }
}

// ------------------CONTRAST------------------

.contrast {
  .banners-list-v3 {
    background-color: $contrastBlackColor;
    border-top: 2px solid $contrastYellowColor;

    &-item {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: 3px solid $contrastYellowColor;

      &:is(a) {
        &:hover,
        &:focus {
          color: $contrastBlackColor;
          background-color: $contrastWhiteColor;
          border-color: $contrastWhiteColor;
        }
      }
    }
  }
}
