.cookies-message {
  &-container {
    position: fixed;
    bottom: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    width: 100%;
    padding: 10px 20px;
    background-color: $primaryColor50;
    border-top: 1px solid $primaryColor950;
    @include fontXs();
  }

  &-text {
    text-align: center;
    color: $darkTextColor;
  }

  &-link {
    text-decoration: none;
    color: $primaryColor900;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;

    .btn {
      @include fontXs(700);
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .cookies-message {
    &-container {
      background-color: $contrastBlackColor;
      border-top-color: $contrastYellowColor;
    }

    &-text {
      color: $contrastYellowColor;
    }

    &-link {
      color: $contrastWhiteColor;
    }
  }
}