@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin font($size, $lineHeight: 1.5, $fontWeight: 400) {
  font-size: $size;
  font-size: calculateRem($size);

  @if str-index($lineHeight + unquote(""), 'px') {
    line-height: calculateRem($lineHeight);
  } @else {
    line-height: $lineHeight;
  }

  font-weight: $fontWeight;
}

@function aspectHeight($width, $aspect) {
  $height: $width / $aspect;
  @return #{$height}px;
}

@mixin transition($property...) {
  transition-property: $property;
  transition-duration: $transitionDuration;
  transition-timing-function: $transitionTimingFunction;
}


@import "**/!(colors)/*.scss";
