:where(.btn) {
  display: inline-flex;
  align-content: center;
  align-items: center;
  gap: 4px;
  margin: 0;
  cursor: pointer;
  user-select: none;

  outline: none;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  text-transform: none;

  font-family: $fontFamily;
  font-weight: 700;

  color: $lightTextColor;

  background-color: $greyColor700;
  border: 2px solid transparent;
  border-radius: $borderRadiusLg;
  -webkit-tap-highlight-color: transparent;

  @include transition(box-shadow, background-color, background-image, color, border-color);

  & > * {
    flex-shrink: 0;
  }

  &:disabled,
  &.disabled {
    cursor: default;
    pointer-events: none;
    color: $greyColor500;
    background-color: $greyColor100;
  }
}

:where(.btn.btn-small) {
  padding: 10px;
  @include fontXs(700);
}

:where(.btn:not(.btn-small, .btn-large)) {
  padding: 12px;
  @include fontSm(700);
}

:where(.btn.btn-large) {
  padding: 14px;
  @include fontMd(700);
}

:where(.btn.btn-primary) {
  color: $primaryColor50;
  background-color: $primaryColor950;

  &:hover:not(:disabled, .disabled, .btn-loading),
  &:focus:not(:disabled, .disabled, .btn-loading) {
    color: $primaryColor50;
    background-color: $primaryColor990;
  }

  &:focus:not(:disabled, .disabled, .btn-loading),
  &:active:not(:disabled, .disabled, .btn-loading) {
    border-color: $primaryColor600;
  }
}

:where(.btn.btn-secondary) {
  color: $primaryColor900;
  background-color: $whiteColor;
  border-color: $primaryColor900;

  &:hover:not(:disabled, .disabled, .btn-loading),
  &:focus:not(:disabled, .disabled, .btn-loading) {
    color: $primaryColor950;
    background-color: $primaryColor100;
    border-color: $primaryColor950;
  }
}

:where(.btn.btn-outline) {
  color: $primaryColor50;
  background-color: transparent;
  border-color: $primaryColor50;

  &:hover:not(:disabled, .disabled, .btn-loading),
  &:focus:not(:disabled, .disabled, .btn-loading) {
    color: $primaryColor950;
    background-color: $primaryColor50;
    border-color: $primaryColor950;
  }
}

:where(.btn.btn-tertiary) {
  color: $primaryColor950;
  background-color: transparent;
  border-color: transparent;

  &:hover:not(:disabled, .disabled, .btn-loading),
  &:focus:not(:disabled, .disabled, .btn-loading) {
    color: $primaryColor900;
    background-color: transparent;
    border-color: transparent;
  }
}

:where(.btn.btn-destructive-primary) {
  color: $lightTextColor;
  background-color: $errorColor500;
  border-color: $errorColor500;

  &:hover:not(:disabled, .disabled, .btn-loading),
  &:focus:not(:disabled, .disabled, .btn-loading) {
    color: $lightTextColor;
    background-color: $errorColor100;
    border-color: $errorColor100;
  }
}

:where(.btn.btn-destructive-secondary) {
  color: $errorColor500;
  background-color: $errorColor100;
  border-color: $errorColor100;

  &:hover:not(:disabled, .disabled, .btn-loading),
  &:focus:not(:disabled, .disabled, .btn-loading) {
    color: $errorColor500;
    background-color: $errorColor100;
    border-color: $errorColor500;
  }
}

:where(.btn.btn-success-primary) {
  color: $lightTextColor;
  background-color: $successColor500;
  border-color: $successColor500;

  &:hover:not(:disabled, .disabled, .btn-loading),
  &:focus:not(:disabled, .disabled, .btn-loading) {
    color: $lightTextColor;
    background-color: $successColor100;
    border-color: $successColor100;
  }
}

:where(.btn.btn-success-secondary) {
  color: $successColor500;
  background-color: $successColor100;
  border-color: $successColor100;

  &:hover:not(:disabled, .disabled, .btn-loading),
  &:focus:not(:disabled, .disabled, .btn-loading) {
    color: $successColor500;
    background-color: $successColor100;
    border-color: $successColor500;
  }
}

:where(.btn.btn-loading) {
  position: relative;
  cursor: default;
  pointer-events: none;
  opacity: 1;
  color: transparent !important;
  transition: all 0s linear, opacity $transitionDuration $transitionTimingFunction;
  --size: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--size);
    height: var(--size);
    border: 4px solid rgba(0, 0, 0, 0.15);
    border-radius: $roundedBorderRadius;
    translate: -50% -50%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--size);
    height: var(--size);
    border-width: 4px;
    border-style: solid;
    border-color: $whiteColor transparent transparent;
    border-radius: $roundedBorderRadius;
    animation: animation-spin 0.6s linear infinite;
    translate: -50% -50%;
  }
}

:where(.btn-group) {
  display: inline-flex;
  gap: 0;
  flex-direction: row;

  .btn {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: $borderRadiusSm;
      border-bottom-left-radius: $borderRadiusSm;
    }

    &:last-child {
      border-top-right-radius: $borderRadiusSm;
      border-bottom-right-radius: $borderRadiusSm;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .btn {
    color: $contrastYellowColor !important;
    background-color: $contrastBlackColor !important;
    border-color: $contrastYellowColor !important;

    &:hover:not(:disabled, .disabled, .btn-loading),
    &:focus:not(:disabled, .disabled, .btn-loading) {
      color: $contrastBlackColor !important;
      background-color: $contrastYellowColor !important;
    }

    &.btn-loading {
      color: transparent !important;

      &::before {
        border-color: rgba(0, 0, 0, 0.15) !important;
      }

      &::after {
        border-color: $contrastYellowColor transparent transparent !important;
      }
    }

    &:disabled,
    &.disabled {
      color: $contrastWhiteColor !important;
      background-color: $contrastBlackColor !important;
      border: $contrastWhiteColor !important;
    }
  }
}
