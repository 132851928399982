$menuLvl2Width: 40;

.main-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1002;
  display: flex;
  visibility: hidden;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  transform: translateX(100%);
  background-color: $whiteColor;

  @media only screen and #{$lg-min-width} {
    position: static;
    visibility: unset;
    max-width: unset;
    transform: unset;
    background-color: unset;
  }

  &.is-open {
    visibility: visible;
    transform: translateX(0);
  }

  &.is-toggling {
    @include transition(transform, visibility);
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
    padding: 10px 16px;

    @media only screen and #{$lg-min-width} {
      display: none;
    }
  }

  &-close {
    position: relative;
    width: var(--size);
    height: var(--size);
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-radius: $borderRadiusSm;
    --size: 32px;
    @include transition(box-shadow, background-color, color, border-color);

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 2px;
      background-color: $blackColor;
      translate: -50% -50%;
    }

    &::before {
      rotate: -45deg;
    }

    &::after {
      rotate: 45deg;
    }

    &:hover,
    &:focus {
      background-color: $primaryColor950;

      &::before,
      &::after {
        background-color: $whiteColor;
      }
    }
  }

  &-container {
    overflow-y: auto;
    flex-grow: 1;

    @media only screen and #{$lg-min-width} {
      overflow-y: unset;
    }
  }

  &-list {
    position: relative;
    display: flex;
    gap: 0;
    flex-direction: column;
    height: 100%;

    @media only screen and #{$lg-min-width} {
      justify-content: end;
      column-gap: 8px;
      flex-direction: row;
    }

    &.menu-lvl2 {
      position: absolute;
      top: 100%;
      display: none;
      gap: 0;
      flex-direction: column;
      flex-wrap: nowrap;
      height: auto;
      background-color: $whiteColor;
      box-shadow: $shadowMd;

      .main-menu-link {
        text-align: left;
      }
    }

    &.menu-lvl2[aria-expanded="true"] {
      display: flex;
    }

    &.menu-lvl2 {
      top: calc(100% + 12px);
      left: 0;
      width: 100%;
      border-bottom-right-radius: calc(#{$borderRadiusLg} + 2px);
      border-bottom-left-radius: calc(#{$borderRadiusLg} + 2px);

      &.has-opened-list {
        width: $menuLvl2Width * 1%;
      }

      .main-menu-item:last-child {
        .main-menu-link {
          border-bottom-right-radius: $borderRadiusLg;
          border-bottom-left-radius: $borderRadiusLg;
        }
      }
    }
  }

  &-item {
    &:is(.show) {
      &:is(.menu-item-lvl1) {
        & > .main-menu-link {
          color: $primaryColor950;
          border-bottom-color: $primaryColor950;
        }
      }

      &:is(.menu-item-lvl2) {
        & > .main-menu-link {
          color: $lightTextColor;
          background-color: $primaryColor950;
        }
      }
    }

    &:is(.extended) {
      @media only screen and #{$lg-min-width} {
        & > .main-menu-link {
          padding-right: 36px;

          &::after {
            content: $ti-icon-chevron-right;
            position: absolute;
            top: 50%;
            right: 12px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-grow: 0;
            flex-shrink: 0;
            width: var(--size);
            height: var(--size);
            font-family: $fontIcon;
            --size: 16px;
            rotate: 90deg;
            translate: 0 -50%;
            @include fontXl();
            @include transition(transform);
          }
        }

        & > .menu-item-lvl2::after {
          transform: rotate(90deg);
        }

        &.show > .menu-item-lvl1::after {
          transform: rotate(180deg);
        }

        &.show > .menu-item-lvl2::after {
          transform: rotate(0);
        }
      }
    }

  }

  &-link {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    height: 100%;
    padding: 16px 12px;
    text-align: center;
    white-space: nowrap;
    color: $darkTextColor;
    background-color: $whiteColor;
    border: none;
    @include fontMd();
    @include transition(box-shadow, background-color, color, border-color);

    @media only screen and #{$lg-min-width} {
      padding: 12px;
      border-bottom: 4px solid transparent;
    }

    &:is(a, button) {
      &:is(.menu-item-lvl1) {
        @media only screen and #{$lg-max-width} {
          &:hover,
          &:focus {
            text-decoration: none;
            color: $lightTextColor;
            background-color: $primaryColor950;
            box-shadow: none;

            &:is(.active) {
              color: $lightTextColor;
              background-color: $primaryColor900;
            }
          }
        }

        @media only screen and #{$lg-min-width} {
          &:hover,
          &:focus {
            text-decoration: none;
            color: $primaryColor950;
            border-bottom-color: $primaryColor950;
            box-shadow: none;

            &:is(.active) {
              color: $primaryColor900;
              border-bottom-color: $primaryColor900;
            }
          }
        }
      }

      &:is(.menu-item-lvl2) {
        &:hover,
        &:focus {
          text-decoration: none;
          color: $lightTextColor;
          background-color: $primaryColor950;
          box-shadow: none;

          &:is(.active) {
            color: $lightTextColor;
            background-color: $primaryColor950;
          }
        }
      }
    }

    &:is(.active) {
      &:is(.menu-item-lvl1) {
        @media only screen and #{$lg-max-width} {
          color: $lightTextColor;
          background-color: $primaryColor950;
        }

        @media only screen and #{$lg-min-width} {
          color: $primaryColor950;
          border-bottom-color: $primaryColor950;
        }
      }

      &:is(.menu-item-lvl2) {
        color: $lightTextColor;
        background-color: $primaryColor990;
      }
    }
  }

  &-options {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
    padding: 24px;

    @media only screen and #{$lg-min-width} {
      display: none;
    }

    &-item {
      flex-shrink: 0;
    }

    &-link {
      display: block;
      width: auto;
      height: 32px;
      font-size: 0;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: $borderRadiusSm;
      @include transition(filter);

      &:hover,
      &:focus {
        filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
      }

      .item-icon {
        width: auto;
        height: 32px;
        background-size: contain;
      }
    }
  }

  &-shadow {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    visibility: hidden;
    width: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    @include transition(visibility, opacity);

    @media only screen and #{$lg-min-width} {
      display: none;
    }

    &.is-open {
      visibility: visible;
      opacity: 1;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .main-menu {
    background-color: $contrastBlackColor;
    border-left: 2px solid $contrastYellowColor;

    @media only screen and #{$lg-min-width} {
      background-color: $contrastBlackColor;
      border-left: none;
    }

    &-close {
      background-color: transparent;
      border: none;

      &::before,
      &::after {
        background-color: $contrastYellowColor;
      }

      &:hover,
      &:focus {
        background-color: $contrastYellowColor;

        &::before,
        &::after {
          background-color: $contrastBlackColor;
        }
      }
    }

    &-list {
      &.menu-lvl2 {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border: 2px solid $contrastYellowColor;
        box-shadow: none;
      }
    }

    &-item {
      &:is(.show) {
        &:is(.menu-item-lvl1) {
          & > .main-menu-link {
            color: $contrastYellowColor;
            border-bottom-color: $contrastYellowColor;
          }
        }

        &:is(.menu-item-lvl2) {
          & > .main-menu-link {
            color: $contrastBlackColor;
            background-color: $contrastWhiteColor;
          }
        }
      }
    }

    &-link {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: none;
      border-bottom: 4px solid transparent;

      &:is(a, button) {
        &:is(.menu-item-lvl1) {
          @media only screen and #{$lg-max-width} {
            &:hover,
            &:focus {
              text-decoration: none;
              color: $contrastBlackColor;
              background-color: $contrastYellowColor;
              box-shadow: none;

              &:is(.active) {
                color: $contrastBlackColor;
                background-color: $contrastWhiteColor;
              }
            }
          }

          @media only screen and #{$lg-min-width} {
            &:hover,
            &:focus {
              text-decoration: none;
              color: $contrastYellowColor;
              border-bottom-color: $contrastYellowColor;
              box-shadow: none;

              &:is(.active) {
                color: $contrastWhiteColor;
                border-bottom-color: $contrastWhiteColor;
              }
            }
          }
        }

        &:is(.menu-item-lvl2) {
          &:hover,
          &:focus {
            text-decoration: none;
            color: $contrastBlackColor;
            background-color: $contrastYellowColor;
            box-shadow: none;

            &:is(.active) {
              color: $contrastBlackColor;
              background-color: $contrastWhiteColor;
            }
          }
        }
      }

      &:is(.active) {
        &:is(.menu-item-lvl1) {
          @media only screen and #{$lg-max-width} {
            color: $contrastBlackColor;
            background-color: $contrastWhiteColor;
          }

          @media only screen and #{$lg-min-width} {
            color: $contrastWhiteColor;
            border-bottom-color: $contrastWhiteColor;
          }
        }

        &:is(.menu-item-lvl2) {
          color: $contrastBlackColor;
          background-color: $contrastWhiteColor;
        }
      }
    }

    &-options {
      &-link {
        height: 42px;
        padding: 5px;
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border: 1px solid $contrastYellowColor;

        &:hover,
        &:focus {
          color: $contrastBlackColor;
          background-color: $contrastYellowColor;
          border: 1px solid $contrastYellowColor;
          filter: none;
        }
      }
    }

    &-shadow {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
}
