.form {
  &-label {
    color: $darkTextColor;
    @include fontMd();
  }

  &-control {
    display: block;
    width: 100%;
    padding: 12px;
    appearance: none;
    color: $darkTextColor;
    background-color: $whiteColor;
    border: 1px solid transparent;
    border-bottom-color: $primaryColor400;
    @include fontMd();
    @include transition(box-shadow, background-color, color, border-color);

    &:hover,
    &:focus {
      border-color: $primaryColor950;

      &::placeholder {
        opacity: 1;
        color: $primaryColor950;
      }
    }

    &::placeholder {
      opacity: 1;
      color: $primaryColor400;
      @include transition(color);
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: none;
      color: $greyColor500;
      background-color: $whiteColor;
      border-color: $greyColor300;

      &::placeholder {
        color: $greyColor500;
      }
    }

    &.error {
      color: $errorColor500;
      background-color: $whiteColor;
      border-color: $errorColor500;

      &::placeholder {
        color: $errorColor500;
      }
    }
  }

  &-datepicker {
    padding-right: 44px;

    &-wrapper {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 16px;
        width: var(--size);
        height: var(--size);
        pointer-events: none;
        background-color: $whiteColor;
        background-image: url("#{$assetsPath}/images/calendar.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        --size: 20px;
        translate: 0 -50%;
      }
    }
  }

  .js-datepicker-container {
    position: relative;
  }

  &-select {
    position: relative;

    @media only screen and #{$sm-min-width} {
      max-width: 250px;
    }

    @media only screen and #{$md-min-width} {
      max-width: 300px;
    }

    @media only screen and #{$lg-min-width} {
      max-width: 350px;
    }

    .form-control {
      padding-right: 44px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 16px;
      width: var(--size);
      height: var(--size);
      pointer-events: none;
      background-image: url("#{$assetsPath}/images/expand.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      --size: 20px;
      translate: 0 -50%;
    }
  }

  &-choice {
    position: relative;
    display: inline-flex;
    align-items: center;

    &-input {
      position: absolute;
      top: 50%;
      left: 0;
      display: inline-flex;
      flex-grow: 0;
      flex-shrink: 0;
      width: var(--size);
      height: var(--size);
      appearance: none;
      transform: translateY(-50%);
      color: $darkTextColor;
      background-color: $whiteColor;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 1rem;
      border: 1px solid $greyColor300;
      border-radius: $roundedBorderRadius;
      --size: 1.25rem;
      @include transition(box-shadow, background-color, color, border-color);

      &.checkbox {
        border-radius: $borderRadiusSm;
      }

      &:hover,
      &:focus {
        background-color: $primaryColor100;
        border-color: $primaryColor800;

        ~ .form-choice-label {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      &:checked {
        color: $lightTextColor;
        background-color: $primaryColor950;
        background-image: url("#{$assetsPath}/images/check.svg");
        border-color: $primaryColor950;
      }

      &:disabled {
        cursor: not-allowed;
        pointer-events: none;
        color: $greyColor500;
        background-color: $greyColor100;
        border-color: $greyColor300;
      }
    }

    &-label {
      user-select:none;
      position: relative;
      display: block;
      flex-shrink: 0;
      width: 100%;
      padding: 12px 0 12px 32px;
      color: $darkTextColor;
      @include fontMd();
    }
  }

  &-search {
    position: relative;

    &:focus-within,
    &:has(.form-search-submit:hover, .form-search-submit:focus) {
      .form-control {
        border-color: $primaryColor950;

        &::placeholder {
          opacity: 1;
          color: $primaryColor950;
        }
      }
    }

    .form-control {
      padding: 12px 50px 12px 16px;
    }

    &-submit {
      position: absolute;
      top: 50%;
      right: 1px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: calc(100% - 2px);
      color: $primaryColor400;
      background-color: $whiteColor;
      border-radius: 0;
      aspect-ratio: 1 / 1;
      translate: 0 -50%;
      @include transition(box-shadow, background-color, color, border-color);

      &:hover,
      &:focus {
        color: $lightTextColor;
        background-color: $primaryColor950;
      }

      &:disabled,
      &.disabled {
        cursor: not-allowed;
        background-color: $greyColor100 !important;
      }
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .form {
    &-label {
      color: $contrastYellowColor;
    }

    &-control {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border-color: $contrastYellowColor;

      &:hover,
      &:focus {
        border-color: $contrastWhiteColor;
      }

      &::placeholder {
        opacity: 1;
        color: $contrastYellowColor;
      }

      &:disabled {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border-color: $contrastYellowColor;

        &::placeholder {
          opacity: 1;
          color: $contrastYellowColor;
        }
      }

      &.error {
        color: $errorColor100;
        background-color: $contrastBlackColor;
        border-color: $errorColor100;

        &::placeholder {
          opacity: 1;
          color: $errorColor100;
        }
      }
    }

    &-datepicker {
      &-wrapper {
        &::after {
          background-color: transparent;
          filter: brightness(0) invert(1);
        }
      }
    }

    &-select {
      &::after {
        filter: brightness(0) invert(1);
      }
    }

    &-choice {
      &-input {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border-color: $contrastYellowColor;

        &:hover,
        &:focus {
          background-color: $contrastWhiteColor;
          border-color: $contrastWhiteColor;
        }

        &:checked {
          color: $contrastYellowColor;
          background-color: $contrastBlackColor;
          border-color: $contrastYellowColor;
        }

        &:disabled {
          color: $greyColor500;
          background-color: $greyColor100;
          border-color: $greyColor100;
        }
      }

      &-label {
        color: $contrastYellowColor;
      }
    }

    &-search {
      &:focus-within,
      &:has(.form-search-submit:hover, .form-search-submit:focus) {
        .form-control {
          border-color: $contrastWhiteColor;

          &::placeholder {
            color: $contrastWhiteColor;
          }
        }
      }

      &-submit {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border-color: $contrastBlackColor;

        &:hover,
        &:focus {
          color: $contrastBlackColor;
          background-color: $contrastYellowColor;
          border-color: $contrastYellowColor;
        }

        &:disabled,
        &.disabled {
          border-color: $greyColor100 !important;
        }
      }
    }
  }
}
