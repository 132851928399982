.shortcuts-v1 {
  .subheading {
    margin-bottom: 4px;
  }

  .heading {
    margin-bottom: 24px;
  }

  &-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;

    @media only screen and #{$sm-min-width} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and #{$lg-min-width} {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 24px;
    }
  }

  &-element {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    height: 100%;
    padding: 12px;
    text-align: center;
    word-break: break-word;
    color: $primaryColor900;
    background-color: $whiteColor;
    border: 2px solid $primaryColor900;
    border-radius: $borderRadiusLg;
    @include fontLg();
    @include transition(box-shadow, background-color, border-color, color);

    &:hover,
    &:focus {
      text-decoration: none;
      color: $primaryColor50;
      background-color: $primaryColor950;
      border-color: $primaryColor900;

      .shortcuts-v1-icon {
        filter: brightness(0) invert(1);
      }
    }
  }

  &-icon {
    display: inline-block;
    flex-shrink: 0;
    width: var(--size);
    height: var(--size);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    --size: 60px;
    @include transition(filter);
  }
}

// ------------------CONTRAST------------------

.contrast {
  .shortcuts-v1 {
    color: $contrastYellowColor;
    background-color: $contrastBlackColor;
    border-top: 2px solid $contrastYellowColor;

    &-element {
      color: $contrastWhiteColor  !important;
      background-color: $contrastBlackColor  !important;
      border-color: $contrastYellowColor  !important;

      &:hover,
      &:focus {
        color: $contrastBlackColor  !important;
        background-color: $contrastYellowColor  !important;
        border-color: $contrastYellowColor  !important;

        .shortcuts-v1-icon {
          filter: brightness(0)  !important;
        }
      }

      .shortcuts-v1-icon {
        filter: brightness(0) invert(1) !important;
      }
    }
  }
}
