.article {
  :where(.heading) {
    color: $darkTextColor;
    @include fontH4();
  }

  &-heading {
    @include fontH3();

    &-image {
      width: 100%;
    }
  }

  &-wrapper {
    display: grid;
    gap: 32px;
    grid-template-columns: 1fr;

    @media only screen and #{$lg-min-width} {
      gap: 48px;
      grid-template-columns: 2fr 1fr;
    }

    @media only screen and #{$xl-min-width} {
      gap: 64px;
    }
  }

  &-content {
    overflow-x: auto;

    & > *:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  &-text {
    :where(h1, h2, h3, h4, h5, h6) {
      margin-bottom: 12px;
    }

    :where(p) {
      margin-bottom: 24px;
      color: $greyColor900;
    }

    :where(a) {
      color: $greyColor900;
      @include fontH6();

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    :where(img) {
      margin-bottom: 16px;
    }

    :where(table) {
      margin-bottom: 24px;
    }

    :where(ol, ul) {
      margin-bottom: 24px;
      padding-inline-start: 30px;

      :where(ol, ul) {
        margin-bottom: 0;
      }
    }

    :where(ol) {
      list-style: decimal;
    }

    :where(ul) {
      list-style: disc;

      :where(li) {
        &::marker {
          color: $greyColor900;
        }
      }
    }
  }

  &-photos {
    &-list {
      display: grid;
      gap: 24px;
      margin-bottom: 12px;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

      @media only screen and #{$lg-min-width} {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      }

      @media only screen and #{$xxl-min-width} {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      }
    }

    &-link {
      &:hover,
      &:focus {
        .article-photos-image {
          transform: scale(0.95);
        }
      }
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include transition(transform);
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-more {
    &-list {
      display: flex;
      gap: 8px;
      flex-direction: column;
    }

    &-grid {
      display: grid;
      gap: 16px;

      @media only screen and #{$md-min-width} {
        gap: 32px;
        grid-template-columns: repeat(2, 1fr);
      }

      @media only screen and #{$xl-min-width} {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &-link {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px 16px;
      color: $darkTextColor;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: $borderRadiusSm;
      @include transition(box-shadow, background-color, color, border-color);

      &::before {
        content: $ti-icon-link;
        display: inline-block;
        font-family: $fontIcon;
        font-size: 1.5em;
      }

      &:hover,
      &:focus,
      &.active {
        text-decoration: none;
        border-color: $primaryColor950;
      }
    }
  }

  &-meta {
    & > *:not(:last-child) {
      margin-bottom: 12px;
    }

    &-separator {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 12px;
      width: max-content;
      color: $darkTextColor;
      border-radius: $borderRadiusSm;
      @include fontMd();
      @include transition(box-shadow, background-color, color, border-color);
    }
  }

  &-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-direction: column;

    @media only screen and #{$sm-min-width} {
      justify-content: space-between;
      gap: 16px;
      flex-direction: row;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .article {
    :where(.heading) {
      color: $contrastYellowColor;
    }

    &-text {
      :where(p) {
        color: $contrastYellowColor;
      }

      :where(a) {
        color: $contrastWhiteColor;

        &:hover,
        &:focus {
          text-decoration: underline;
          color: $contrastYellowColor;
        }
      }

      :where(ul) {
        :where(li) {
          &::marker {
            color: $contrastYellowColor;
          }
        }
      }
    }

    &-more {
      &-link {
        color: $contrastWhiteColor;
        background-color: $contrastBlackColor;
        border: 2px solid transparent;

        &:hover,
        &:focus,
        &.active {
          color: $contrastYellowColor;
          background-color: $contrastBlackColor;
          border: 2px solid $contrastYellowColor;
        }
      }
    }

    &-meta {
      &-item {
        color: $contrastWhiteColor;
        background-color: $contrastBlackColor;
      }
    }
  }
}
