.alert {
  margin-top: 32px;
  padding: 16px;
  text-align: center;
  border: 1px solid $greyColor900;
  border-radius: $borderRadiusSm;

  &::before {
    content: '';
    margin-right: 16px;
    vertical-align: middle;
    font-family: $fontIcon;
    font-size: 1.5em;
  }

  &-info {
    color: $infoColor900;
    background-color: $infoColor50;
    border-color: $infoColor900;

    &::before {
      content: $ti-icon-info-circle;
    }
  }

  &-success {
    color: $successColor900;
    background-color: $successColor50;
    border-color: $successColor900;

    &::before {
      content: $ti-icon-circle-check;
    }
  }

  &-error {
    color: $errorColor900;
    background-color: $errorColor50;
    border-color: $errorColor900;

    &::before {
      content: $ti-icon-alert-circle;
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .alert {
    color: $contrastYellowColor;
    background-color: $contrastBlackColor;
    border-color: $contrastYellowColor;
  }
}
