.sub-navigation {
  margin-bottom: 32px;

  @media only screen and #{$md-min-width} {
    margin-bottom: 64px;
  }

  &-list {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  }

  &-item {
    align-self: start;
  }

  &-link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .item-icon-wrapper,
    .item-text {
      color: $primaryColor50;
      background-color: $primaryColor950;
      border: 1px solid $primaryColor950;
    }

    .item-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--size);
      max-width: var(--max-size);
      height: var(--size);
      max-height: var(--max-size);
      border-radius: $roundedBorderRadius;
      --max-size: 144px;
      --size: 100%;
      aspect-ratio: 1 / 1;
      @include transition(box-shadow, background-color, color, border-color);

      @media only screen and #{$md-max-width} {
        --max-size: 72px;
      }
    }

    .item-icon {
      width: var(--size);
      height: var(--size);
      border-radius: $roundedBorderRadius;
      --size: 90%;
      aspect-ratio: 1 / 1;
      @include transition(filter);
    }

    .item-text {
      width: 100%;
      padding: 14px;
      text-align: center;
      border-radius: $borderRadiusSm;
      @include fontMd(700);
      @include transition(box-shadow, background-color, color, border-color);
    }

    &:is(a, button) {
      &.active {
        text-decoration: none;

        .item-icon-wrapper,
        .item-text {
          color: $primaryColor990;
          background-color: $whiteColor;
          border-color: $primaryColor990;
        }

        .item-icon {
          filter: brightness(10) invert(1);
        }
      }
      
      &:hover,
      &:focus {
        text-decoration: none;

        .item-icon-wrapper,
        .item-text {
          color: $primaryColor990;
          background-color: $primaryColor100;
          border-color: $primaryColor990;
        }

        .item-icon {
          filter: brightness(10) invert(1);
        }
      }
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .sub-navigation {
    &-link {
      .item-icon,
      .item-text, {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border-color: $contrastYellowColor;
      }

      &:is(a, button) {
        &:hover,
        &:focus,
        &.active {
          text-decoration: none;

          .item-icon,
          .item-text {
            color: $contrastBlackColor;
            background-color: $contrastYellowColor;
            border-color: $contrastYellowColor;
          }
        }
      }
    }
  }
}
