.shortcuts-v2 {
  padding: 64px 0;
  background-color: $primaryColor950;

  .subheading {
    margin-bottom: 4px;
  }

  .heading {
    margin-bottom: 24px;
  }

  &-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;

    @media only screen and #{$md-min-width} {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &-element {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    height: 100%;
    padding: 12px;
    text-align: center;
    word-break: break-word;
    color: $primaryColor50;
    background-color: $primaryColor950;
    border: 2px solid $primaryColor50;
    border-radius: $borderRadiusLg;
    @include fontLg();
    @include transition(box-shadow, background-color, border-color, color);

    &:hover,
    &:focus {
      text-decoration: none;
      color: $primaryColor950;
      background-color: $primaryColor50;
      border-color: $primaryColor950;

      .item-icon {
        filter: brightness(0);
      }
    }

    .item-icon {
      display: inline-block;
      flex-shrink: 0;
      width: var(--size);
      height: var(--size);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      --size: 87px;
      filter: brightness(0) invert(1);
      @include transition(filter);
    }
  }
}

// ------------------CONTRAST------------------

.contrast {
  .shortcuts-v2 {
    color: $contrastYellowColor;
    background-color: $contrastBlackColor;
    border-top: 2px solid $contrastYellowColor;

    &-element {
      color: $contrastWhiteColor !important;
      background-color: $contrastBlackColor !important;
      border-color: $contrastYellowColor !important;

      &:hover,
      &:focus {
        color: $contrastBlackColor !important;
        background-color: $contrastYellowColor !important;
        border-color: $contrastYellowColor !important;

        .item-icon {
          filter: brightness(0) !important;
        }
      }

      .item-icon {
        filter: brightness(0) invert(1) !important;
      }
    }
  }
}
