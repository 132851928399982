.banner-contact {
  display: block;
  padding: 48px 0;
  word-break: break-word;
  color: $primaryColor50;
  background-color: $primaryColor950;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  @include fontH4();
  @include transition(background-color, color, border-color);

  @media only screen and #{$sm-min-width} {
    @include fontH3();
  }

  @media only screen and #{$lg-min-width} {
    @include fontH2();
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: $primaryColor990;
    background-color: $whiteColor;
    border-top-color: $primaryColor950;
    border-bottom-color: $primaryColor950;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }

  .ti {
    font-weight: 500;
  }
}

// ------------------CONTRAST------------------

.contrast {
  .banner-contact {
    color: $contrastYellowColor;
    background-color: $contrastBlackColor;
    border-top-color: $contrastYellowColor;
    border-bottom: none;

    &:hover,
    &:focus {
      color: $contrastBlackColor;
      background-color: $contrastYellowColor;
      background-image: none;
    }
  }
}
