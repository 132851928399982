:root {
  --row-gutter: 16px;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

:focus-visible:not(:disabled) {
  outline: $primaryColor900 $outlineStyle $outlineWidth;
  outline-offset: $outlineOffset;
}

:where(.font-large) {
  @include fontLg();
}

:where(.font-x-large) {
  @include fontXl();
}

:where(h1) {
  @include fontH1();
}

:where(h2) {
  @include fontH2();
}

:where(h3) {
  @include fontH3();
}

:where(h4) {
  @include fontH4();
}

:where(h5) {
  @include fontH5();
}

:where(h6) {
  @include fontH6();
}

:where(.section) {
  padding: 42px 0;
}

:where(.section-heading) {
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 12px;
  flex-direction: column;
  margin-bottom: 24px;

  @media only screen and #{$sm-min-width} {
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex-direction: row;
  }

  &.centered {
    align-items: center;
    justify-content: center;
  }
}

:where(.heading) {
  text-wrap: balance;
  color: $primaryColor990;
  @include fontH4(500);

  @media only screen and #{$md-min-width} {
    @include fontH2(500);
  }

  @media only screen and #{$lg-min-width} {
    @include fontHeading(500);
  }
}

:where(.subheading) {
  text-wrap: balance;
  color: $primaryColor990;
  @include fontXs(500);

  @media only screen and #{$md-min-width} {
    @include fontSm(500);
  }

  @media only screen and #{$lg-min-width} {
    @include fontMd(500);
  }
}

:where(a) {
  text-decoration: none;
  color: $primaryColor800;
}

:where(a:not(.btn):hover),
:where(a:not(.btn):focus) {
  text-decoration: underline;
  color: $primaryColor900;
}

:where(p) {
  text-wrap: pretty;
}

:where(.show-more) {
  padding: 10px 0;
  color: $darkTextColor;
  @include fontH6();
  @include transition(box-shadow, background-color, color, border-color);

  &:is(a, button) {
    &:hover,
    &:focus {
      text-decoration: underline;
      color: $primaryColor900;
    }
  }
}

:where(.separator) {
  height: 2px;
  background-color: $whiteColor;
  border: none;

  &.tiny {
    height: 1px;
  }

  &.light {
    background-color: $whiteColor;
  }

  &.gray {
    background-color: $greyColor100;
  }

  &.dark {
    background-color: $greyColor900;
  }
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--row-gutter);
  padding-left: var(--row-gutter);

  @media only screen and #{$sm-min-width} {
    max-width: $smBreakpoint;
  }

  @media only screen and #{$md-min-width} {
    max-width: $mdBreakpoint;
  }

  @media only screen and #{$lg-min-width} {
    max-width: $lgBreakpoint;
    --row-gutter: 24px;
  }

  @media only screen and #{$xl-min-width} {
    max-width: $xlBreakpoint;
  }

  @media only screen and #{$xxl-min-width} {
    max-width: $xxlBreakpoint;
  }

  &-fluid {
    max-width: 100%;
  }
}

.layout {
  background-color: $whiteColor;
}

.map {
  z-index: 1;
  width: 100%;
  height: 430px;
  margin-top: 24px;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: $borderRadiusSm;
  @include transition(box-shadow, background-color, color, border-color);

  &:focus {
    border: 2px solid $primaryColor800;
  }

  .leaflet-right {
    right: $borderRadiusSm;
  }

  .leaflet-control a {
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  .leaflet-bar a {
    @include transition(box-shadow, background-color, color, border-color);

    &:hover,
    &:focus {
      background-color: #F4F4F4;
    }
  }

  .leaflet-marker-icon {
    @include transition(filter);

    &:hover,
    &:focus {
      filter: brightness(0) invert(1);
    }
  }

  a.leaflet-popup-close-button {
    top: 4px;
    right: 4px;
    width: auto;
    height: auto;
    padding: 4px;
    color: $darkTextColor;

    &:focus,
    &:hover {
      color: #C3C3C3;
    }
  }
}

.slick {
  &-list {
    width: 100%;
    height: 100%;
  }

  &-track {
    display: flex;
    align-items: stretch;
    height: 100%;
  }

  &-slide {
    float: unset;
    height: unset;
    width: unset;
    min-height: unset;

    & > div {
      width: 100%;
      height: 100%;
    }
  }

  &-slider {
    .row {
      margin-right: 0;
      margin-left: 0;

      & > [class^="col-"] {
        padding: 0;
      }
    }
  }

  &-arrow {
    position: relative;
    inset: unset;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: var(--size);
    height: var(--size);
    padding: 10px;
    user-select: none;
    outline: none;
    transform: none;
    vertical-align: middle;
    color: $darkTextColor;
    background-color: $whiteColor;
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid $primaryColor950;
    border-radius: $roundedBorderRadius;
    -webkit-tap-highlight-color: transparent;
    --size: 40px;
    @include fontXl(400);
    @include transition(box-shadow, background-color, background-image, color, border-color);

    &:hover,
    &:focus {
      color: $lightTextColor;
      background-color: $primaryColor950;
    }
  }

  &-prev,
  &-next {
    &::before {
      display: none;
    }
  }

  &-dots {
    position: static;
    display: inline-flex;
    flex-wrap: wrap;
    width: max-content;

    li {
      width: var(--size);
      height: var(--size);
      --size: 34px;

      button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 0;

        &::before {
          content: "";
          position: static;
          display: block;
          flex-shrink: 0;
          width: 100%;
          height: 100%;
          font-family: $fontFamily;
          opacity: 1;
          background-color: $primaryColor950;
          border-radius: $roundedBorderRadius;
        }

        &:hover,
        &:focus {
          &::before {
            opacity: 1;
            background-color: $primaryColor990;
          }
        }
      }

      &.slick-active {
        button::before {
          background-color: $primaryColor100;
        }
      }
    }
  }

  &-pause-play {
    z-index: 1;
    display: block;
    width: var(--size);
    height: var(--size);
    --size: 40px;

    button {
      @extend .btn, .btn-primary;
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      border-radius: $borderRadiusSm;
      @include fontXl(400);

      &.slick-pause,
      &.slick-play {
        &::before {
          display: none;
        }
      }
    }
  }
}

.lightbox {
  .lb-caption {
    @include fontMd();
  }

  .lb-number {
    @include fontMd();
  }
}

.datepicker {
  &::after {
    display: none;
  }

  &-dropdown {
    position: absolute;
    margin-top: 8px;
    padding: 14px;
    background-color: $whiteColor;
    border: 1px solid $primaryColor800;
    border-radius: $borderRadiusLg;
    box-shadow: $shadowMd;

    &::before {
      display: none;
    }
  }

  &-switch {
    color: $darkTextColor;
    @include fontH6();
  }

  .prev,
  .next {
    font-size: 0;
    background-image: url("#{$assetsPath}/images/chevron_right.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
  }

  .prev {
    transform: rotate(180deg);
  }

  .day,
  .month,
  .year,
  .decade,
  .century {
    width: var(--size);
    height: var(--size);
    border: 1px solid transparent;
    border-radius: $roundedBorderRadius;
    @include fontH6();
    @include transition(color, background-color, border-color);

    &:hover,
    &:focus {
      background-color: $greyColor100;
    }
  }

  .day {
    --size: 40px;
  }

  .month,
  .year,
  .decade,
  .century {
    display: flex;
    align-items: center;
    justify-content: center;
    --size: 69px;
  }

  .dow {
    color: $greyColor900;
    @include fontH6();
  }

  .today {
    color: $lightTextColor;
    background-color: $primaryColor600;
    border: none;

    &.active {
      border: 1px solid $greyColor900;
    }
  }

  .active:not(.today) {
    color: $darkTextColor;
    background-color: $whiteColor;
    border-color: $greyColor900;

    &:hover,
    &:focus {
      background-color: $greyColor100;
    }
  }
}

.modal {
  z-index: 3001;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    cursor: pointer;
    opacity: 1;
    background-color: $whiteColor;
    border-radius: 100%;
    @include transition(background-color, color, border-color, box-shadow, opacity);

    &:hover,
    &:focus {
      opacity: 1 !important;
      color: $lightTextColor;
      background-color: $primaryColor800;

      .ti {
        color: $lightTextColor;
        @include transition(background-color, color, border-color, box-shadow);

        &::before {
          color: $lightTextColor !important;
        }
      }
    }

    .ti {
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 0;
        color: $darkTextColor;
      }
    }
  }

  &-backdrop {
    z-index: 3000;
  }
}

.js-click-reset {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  display: none;
  width: 44px;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  background-color: $whiteColor;
  border: none;
  border-top-right-radius: $roundedBorderRadius;
  border-bottom-right-radius: $roundedBorderRadius;
  @include transition(background-color, color, border-color, box-shadow, opacity);

  &::before {
    content: "\f00d";
    position: absolute;
    top: 50%;
    left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--size);
    height: var(--size);
    transform: translateY(-50%);
    --size: 20px;
  }

  &:hover,
  &:focus {
    opacity: 1;
    background-color: $greyColor100;
  }

  &.active {
    display: block;
  }
}

// ------------------CONTRAST------------------

.contrast {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }

  :focus-visible {
    outline: $contrastYellowColor $outlineStyle $outlineWidth;
    outline-offset: $outlineOffset;
  }

  :where(.heading) {
    color: $contrastYellowColor;
  }

  :where(.subheading) {
    color: $contrastYellowColor;
  }

  :where(a) {
    color: $contrastYellowColor;
  }

  :where(a:not(.btn):hover),
  :where(a:not(.btn):focus) {
    text-decoration: underline;
    color: $contrastWhiteColor;
  }

  :where(.show-more) {
    color: $contrastYellowColor;

    &:is(a, button) {
      &:hover,
      &:focus {
        color: $contrastWhiteColor;
      }
    }

    &.with-arrow {
      &::after {
        filter: brightness(0) invert(1);
      }
    }
  }

  :where(.separator) {
    background-color: $contrastYellowColor !important;
    border: none;
  }

  .layout {
    background-color: $contrastBlackColor;
  }

  .map {
    background-color: transparent;
    border-color: $contrastYellowColor;

    &:focus {
      border-color: $contrastWhiteColor;
    }

    .leaflet-control {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;

      a {
        color: $contrastYellowColor;

        &:focus,
        &:hover {
          text-decoration: none;
          color: $contrastWhiteColor;
        }
      }
    }

    .leaflet-bar {
      border-color: $contrastYellowColor;

      a {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border-color: $contrastYellowColor;

        &:hover,
        &:focus {
          color: $contrastBlackColor;
          background-color: $contrastYellowColor;
          border-color: $contrastYellowColor;
        }
      }
    }

    .leaflet-marker-icon {
      &:hover,
      &:focus {
        filter: brightness(0) invert(1);
      }
    }

    a.leaflet-popup-close-button {
      color: $contrastBlackColor;

      &:focus,
      &:hover {
        color: $contrastWhiteColor;
      }
    }
  }

  .slick {
    &-arrow {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border-color: $contrastYellowColor;

      &:hover,
      &:focus {
        color: $contrastBlackColor;
        background-color: $contrastYellowColor;
      }
    }

    &-dots {
      li {
        button {
          color: $contrastYellowColor;

          &::before {
            background-color: $contrastYellowColor;
          }

          &:hover,
          &:focus {
            &::before {
              opacity: 1;
              background-color: $contrastBlackColor;
            }
          }
        }

        &.slick-active {
          button::before {
            background-color: $contrastWhiteColor;
          }
        }
      }
    }
  }

  .lightbox {
    .lb-caption,
    .lb-number {
      display: block;
      padding: 4px;
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
    }
  }

  .datepicker {
    &-dropdown {
      background-color: $contrastBlackColor;
      border-color: $contrastYellowColor;
    }

    &-switch {
      color: $contrastYellowColor;
      border: 1px solid transparent;

      &:hover,
      &:focus {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border-color: $contrastYellowColor;
      }
    }

    .prev,
    .next {
      filter: brightness(0) invert(1);

      &:hover,
      &:focus {
        filter: unset;
      }
    }

    .day,
    .month,
    .year,
    .decade,
    .century {
      border: 1px solid transparent;

      &:hover,
      &:focus {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border-color: $contrastYellowColor;
      }

      &.focused {
        color: $contrastBlackColor;
        background-color: $contrastWhiteColor;
        border-color: $contrastWhiteColor;

        &:hover,
        &:focus {
          color: $contrastYellowColor;
          background-color: $contrastBlackColor;
          border-color: $contrastYellowColor;
        }
      }

      &.old,
      &.new {
        color: $contrastYellowColor;
      }
    }

    .dow {
      color: $contrastYellowColor;
    }

    .today {
      color: $contrastBlackColor;
      background-color: $contrastWhiteColor;

      &.active {
        border-color: $contrastYellowColor;
      }
    }

    .active:not(.today) {
      color: $contrastBlackColor;
      background-color: $contrastYellowColor;
      border-color: $contrastYellowColor;

      &:hover,
      &:focus {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border-color: $contrastYellowColor;
      }
    }
  }

  .js-click-reset {
    opacity: 1;
    color: $contrastYellowColor;
    background-color: $contrastBlackColor;
    border: none;

    &::before {
      color: inherit;
    }

    &:hover,
    &:focus {
      opacity: 1;
      color: $contrastWhiteColor;
    }
  }
}
