.news-list-v1 {
  position: relative;
  background-color: $primaryColor50;

  .subheading {
    margin-bottom: 4px;
  }

  .heading {
    margin-bottom: 24px;
  }

  .heading,
  .subheading {
    color: $primaryColor900;
  }

  &-list {
    display: grid;
    justify-content: center;
    gap: 24px;
    margin-bottom: 24px;

    @media only screen and #{$md-min-width} {
      grid-template-columns: 1fr 1fr;
    }
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// ------------------CONTRAST------------------
.contrast {
  .news-list-v1 {
    color: $contrastYellowColor;
    background-color: $contrastBlackColor;
    border-top: 2px solid $contrastYellowColor;

    .heading,
    .subheading {
      color: $contrastYellowColor;
    }
  }
}
