.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;

  &-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10000;
    width: var(--size);
    height: var(--size);
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-radius: $borderRadiusSm;
    --size: 32px;
    @include transition(box-shadow, background-color, color, border-color);

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 2px;
      background-color: $blackColor;
      translate: -50% -50%;
    }

    &::before {
      rotate: -45deg;
    }

    &::after {
      rotate: 45deg;
    }

    &:hover,
    &:focus {
      background-color: $primaryColor950;

      &::before,
      &::after {
        background-color: $whiteColor;
      }
    }
  }

  &-dialog {
    margin: 32px auto 64px;
    padding: 32px;
    user-select: text;
    pointer-events: auto;
    background-color: $whiteColor;
    border: 1px solid $greyColor100;
    border-radius: $borderRadiusSm;
  }

  &-body {
    padding: 0;
    border: none;
  }

  &-content {
    border: none;
  }

  .article-text {
    :where(p) {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .modal {
    &-close {
      background-color: $contrastBlackColor;

      &::before,
      &::after {
        background-color: $contrastYellowColor;
      }

      &:hover,
      &:focus {
        background-color: $contrastYellowColor;

        &::before,
        &::after {
          background-color: $contrastBlackColor;
        }
      }
    }

    &-dialog {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: 2px solid $contrastYellowColor;
    }

    &-content {
      background-color: inherit;
      color: inherit;
    }
  }
}
