.sticky-header {
  display: block !important;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: $whiteColor;
  box-shadow: 0 3px 5px rgba(100, 100, 100, 0.49);

  .sticky-hidden {
    display: none !important;
  }
}

//-------------CONTRAST---------------

.contrast {
  .sticky-header {
    position: relative;
    top: auto;
    background-color: $contrastBlackColor;
    border-bottom: 2px solid $contrastYellowColor;
    box-shadow: none;
  }
}
