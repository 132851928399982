.header {
  &-options {
    display: none;
    padding: 4px 0;
    color: $darkTextColor;
    background-color: $whiteColor;

    @media only screen and #{$lg-min-width} {
      display: block;
    }

    & > .container {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 16px;
    }

    &-menu {
      display: flex;
      align-items: center;
      gap: 24px;
      flex-wrap: wrap;

      &-item {
        flex-shrink: 0;
      }

      &-link {
        display: block;
        width: auto;
        height: 32px;
        font-size: 0;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: $borderRadiusSm;
        @include transition(filter);

        &:hover,
        &:focus {
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
        }

        .item-icon {
          width: auto;
          height: 32px;
          background-size: contain;
        }
      }
    }
  }

  &-search {
    display: none;
    width: 100%;

    @media only screen and #{$lg-min-width} {
      display: block;
      max-width: 320px;
    }

    &-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: var(--size);
      height: var(--size);
      color: $darkTextColor;
      border-radius: $borderRadiusSm;
      --size: 32px;
      @include fontXl();
      @include transition(background-color, color);

      @media only screen and #{$lg-min-width} {
        display: none;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        color: $lightTextColor;
        background-color: $primaryColor950;
      }
    }
  }

  &-main {
    padding: 16px 0;
    color: $darkTextColor;
    background-color: $whiteColor;

    & > .container {
      display: grid;
      align-items: center;
      justify-content: center;
      gap: 16px;
      grid-template-columns: 1fr 76px;

      @media only screen and #{$lg-min-width} {
        grid-template-columns: auto 1fr;
      }
    }

    &-burger {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: var(--size);
      height: var(--size);
      padding: 0;
      cursor: pointer;
      background-color: transparent;
      border: none;
      border-radius: $borderRadiusSm;
      --size: 32px;
      @include fontXl();
      @include transition(background-color, color);

      @media only screen and #{$lg-min-width} {
        display: none;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        color: $lightTextColor;
        background-color: $primaryColor950;
      }
    }

    &-home {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      color: $darkTextColor;
      @include transition(box-shadow, background-color, color, border-color, transform);

      @media only screen and #{$sm-min-width} {
        justify-content: start;
      }

      @media only screen and #{$xl-min-width} {
        gap: 16px;
      }

      @media only screen and #{$xxl-min-width} {
        gap: 32px;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        color: $primaryColor900;

        @media only screen and #{$sm-max-width} {
          transform: translateY(-5px);
        }
      }
    }

    &-logo {
      width: auto;
      height: 100%;
      max-height: 60px;
      object-fit: contain;

      @media only screen and #{$sm-min-width} {
        max-height: 90px;
      }

      @media only screen and #{$lg-min-width} {
        max-height: 120px;
      }
    }

    &-site-name {
      display: block;
      text-transform: uppercase;
      text-wrap: balance;
      word-break: break-word;
      @include fontXs();

      @media only screen and #{$sm-min-width} {
        @include fontMd();
      }

      @media only screen and #{$lg-min-width} {
        @include fontXl(400);
      }

      @media only screen and #{$xl-min-width} {
        @include fontH3(400);
      }
    }
  }

  &-nav {
    &-wrapper {
      display: flex;
      align-items: center;
      gap: 6px;

      @media only screen and #{$lg-min-width} {
        gap: 12px;
        padding: 0;
      }
    }
  }
}

// ------------------CONTRAST------------------
.contrast {
  .header {
    &-options {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;

      &-menu {
        &-link {
          height: 42px;
          padding: 5px;
          color: $contrastYellowColor;
          background-color: $contrastBlackColor;
          border: 1px solid $contrastYellowColor;

          &:hover,
          &:focus {
            color: $contrastBlackColor;
            background-color: $contrastYellowColor;
            border: 1px solid $contrastYellowColor;
            filter: none;
          }
        }
      }
    }

    &-search {
      &-mobile {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border: 1px solid $contrastYellowColor;

        &:hover,
        &:focus {
          color: $contrastBlackColor;
          background-color: $contrastYellowColor;
          border-color: $contrastYellowColor;
        }
      }
    }

    &-main {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border-top: 2px solid $contrastYellowColor;

      &-burger {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border: 1px solid $contrastYellowColor;

        &:hover,
        &:focus {
          color: $contrastBlackColor;
          background-color: $contrastYellowColor;
          border-color: $contrastYellowColor;
        }
      }

      &-home {
        color: $contrastYellowColor;

        &:hover,
        &:focus {
          color: $contrastWhiteColor;
        }
      }
    }
  }
}
