.hospital-schema-v1 {
  &-heading {
    display: flex;
    gap: 16px;
    flex-direction: column;
    margin-bottom: 24px;

    .heading {
      @media only screen and #{$lg-min-width} {
        white-space: nowrap;
      }
    }

    @media only screen and #{$md-min-width} {
      align-items: center;
      justify-content: space-between;
      gap: 36px;
      flex-direction: row;
    }
  }

  &-form {
    display: none;
    max-width: 100%;

    @media only screen and #{$lg-min-width} {
      display: flex;
      gap: 16px;
      flex-direction: row;
      flex-grow: 1;
    }
  }

  .form-select {
    flex-grow: 1;
    max-width: 100%;
  }

  &-mobile {
    &-list {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      flex-direction: column;
      flex-wrap: wrap;

      & & {
        display: none;

        &.opened {
          display: flex;
        }
      }

      @media only screen and #{$sm-min-width} {
        flex-direction: row;
      }

      @media only screen and #{$lg-min-width} {
        display: none !important;
      }
    }

    &-heading {
      justify-content: center;
      width: 100%;
      margin-bottom: 12px;
      color: $primaryColor950;
      border-color: transparent;
      @include fontH4();

      &:hover,
      &:focus {
        color: $primaryColor950;
        background-color: $primaryColor100;
        border-color: $primaryColor950;
      }

      .ti {
        rotate: 90deg;
        @include transition(transform);
      }

      &.opened {
        .ti {
          transform: rotate(180deg);
        }
      }
    }

    &-item {
      flex-grow: 1;
      width: 100%;
      height: 100%;
    }

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 14px;
      text-align: center;
      color: $primaryColor50;
      background-color: $primaryColor950;
      border: 1px solid $primaryColor950;
      border-radius: $borderRadiusSm;
      @include fontMd(700);
      @include transition(box-shadow, background-color, color, border-color);

      &:is(a, button) {
        &:hover,
        &:focus {
          text-decoration: none;
          color: $primaryColor990;
          background-color: $primaryColor100;
          border-color: $primaryColor990;
        }
      }
    }
  }

  &-image-wrapper {
    position: relative;
  }

  &-image {
    display: none;
    width: 100%;
    height: auto;
    object-fit: cover;

    @media only screen and #{$lg-min-width} {
      display: block;
    }
  }

  &-image-svg {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    padding-right: 5%;

    &:has(a:hover, a:focus, a.hover) {
      a:not(:hover):not(:focus):not(.hover) {
        polygon {
          opacity: 0;
        }
      }
    }
  }

  &-image-link {
    position: relative;
    display: block;
    cursor: pointer;
    outline: none;

    polygon {
      opacity: 0.1;
      stroke: $whiteColor;
      stroke-width: 0.2px;
      @include transition(opacity);
    }

    &:hover,
    &:focus,
    &.hover {
      outline: none;

      & polygon {
        opacity: 0.9;
      }
    }
  }

  .tooltip {
    position: absolute;
    z-index: 1000;
    display: none;
    max-width: 200px;
    padding: 12px;
    user-select: none;
    text-align: center;
    pointer-events: none;
    color: $lightTextColor;
    background-color: $primaryColor950;
    border-radius: $borderRadiusSm;
    @include fontSm();
  }
}

// ------------------CONTRAST------------------
.contrast {
  .hospital-schema-v1 {
    border-top: 2px solid $contrastYellowColor;

    &-mobile {
      &-heading {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border-color: $contrastYellowColor;

        &:hover,
        &:focus {
          color: $contrastBlackColor;
          background-color: $contrastYellowColor;
          border-color: $contrastYellowColor;
        }
      }

      &-link {
        color: $contrastYellowColor;
        background-color: $contrastBlackColor;
        border-color: $contrastYellowColor;

        &:is(a, button) {
          &:hover,
          &:focus {
            color: $contrastBlackColor;
            background-color: $contrastYellowColor;
            border-color: $contrastYellowColor;
          }
        }
      }
    }

    &-image-svg {
      &:has(a:hover, a:focus, a.hover) {
        a:not(:hover):not(:focus):not(.hover) {
          polygon {
            opacity: 0.15;
          }
        }
      }
    }

    &-image-link {
      polygon {
        opacity: 0.3;
      }

      &:hover,
      &:focus,
      &.hover {
        & polygon {
          opacity: 0.95;
        }
      }
    }

    .tooltip {
      color: $contrastYellowColor;
      background-color: $contrastBlackColor;
      border: 2px solid $contrastYellowColor;
    }
  }
}
